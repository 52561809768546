import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import PartnersList from '../components/PartnersList';

const PartnerArchiveTemplate = ({
  data: {
    contentfulSiteData: { siteName },
    contentfulPartnersArchive: { title, heading, metaDescription },
    allContentfulPartner: { nodes },
  },
  pageContext,
}) => (
  <Layout title={title} description={metaDescription} siteName={siteName}>
    <main>
      <Banner heading={heading || title} isExtended={true} />
      <PartnersList items={nodes} meta={pageContext} />
    </main>
  </Layout>
);

export const PartnerArchiveTemplateQuery = graphql`
  query PartnerArchiveTemplateQuery($limit: Int!, $skip: Int!) {
    contentfulSiteData {
      siteName
    }
    contentfulPartnersArchive {
      title
      heading {
        raw
      }
      metaDescription
    }
    allContentfulPartner(limit: $limit, skip: $skip) {
      nodes {
        about {
          about
        }
        logo {
          gatsbyImageData(width: 440, height: 440)
          title
        }
        name
        link
        theme {
          title
          slug
        }
      }
    }
  }
`;

export default PartnerArchiveTemplate;
