import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container } from './ui';
import PartnersCard from './PartnerCard';
import Pagination from './Pagination';

const StyledPartnersList = styled.section``;

const StyledContainer = styled(Container)`
  display: flex;
`;

const StyledInner = styled.div`
  display: grid;
  gap: 22px;
  margin: auto;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const StyledPagination = styled(Pagination)`
  margin-top: 40px;
  margin-left: auto;
`;

const PartnersList = ({ items, meta }) => (
  <StyledPartnersList>
    <StyledContainer>
      <StyledInner>
        {items.map((item, i) => (
          <PartnersCard key={i} {...item} />
        ))}
      </StyledInner>
      <StyledPagination {...meta} />
    </StyledContainer>
  </StyledPartnersList>
);
export default PartnersList;
