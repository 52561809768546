import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Link } from './ui';

const StyledPartnerCard = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 240px;
  box-shadow: 0 4px 12px 0 ${standardColours.transparentBlack(0.2)};
  border-radius: 10px;
  background-color: ${standardColours.white};
  z-index: ${zIndexLayers.first};
`;

const StyledExternalLink = styled.a``;

const StyledTop = styled.div`
  padding: 20px;
  border-bottom: solid 1px ${brandColours.denary};
`;

const StyledImage = styled(GatsbyImage)`
  width: 200px;
  height: 200px;
  padding: 20px;
`;

const StyledBottom = styled.div`
  width: 240px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.h3`
  ${fontSize(20)};
  margin-bottom: 25px;
`;
const StyledText = styled.p`
  ${fontSize(14)};
  line-height: 20px;
  margin-bottom: 20px;
`;

const StyledTag = styled.span`
  ${fontSize(14)};
  color: ${standardColours.black};
  font-weight: ${fontWeights.bold};
  width: 100%;
  margin-bottom: 10px;
  margin-top: auto;
`;

const StyledLink = styled(Link)`
  ${fontSize(14)};
  width: 100%;
  text-decoration: underline;
`;

const PartnersCard = ({ about, logo, name, link, theme: { title, slug } }) => (
  <StyledPartnerCard>
    <StyledExternalLink href={link} rel="noreferrer" target="_blank">
      <StyledTop>
        <StyledImage image={logo.gatsbyImageData} alt={logo.title} />
      </StyledTop>
    </StyledExternalLink>
    <StyledBottom>
      <StyledHeading>
        <StyledExternalLink href="#" rel="noreferrer" target="_blank">
          {name}
        </StyledExternalLink>
      </StyledHeading>
      <StyledText>{about.about}</StyledText>
      <StyledTag>As seen in:</StyledTag>
      <StyledLink to={`/curriculum/${slug}`}>{title}</StyledLink>
    </StyledBottom>
  </StyledPartnerCard>
);
export default PartnersCard;
